<template>
  <div v-if="localCampaign" :class="cols && `d-flex flex-${cols}x-cols`">
    <template v-if="readOnly">
      <div class="d-flex">
        <fluency-icon type="add" class="text-success mr-1 mt-1"></fluency-icon>
        <span>{{positiveTargetMethodText}}</span>
      </div>
      <div v-if="hasNegativeGeoTargetMethodCapability" class="d-flex">
        <fluency-icon type="subtract" class="text-danger mr-1 mt-1"></fluency-icon>
        <span>{{negativeTargetMethodText}}</span>
      </div>
    </template>
    <template v-else>

      <div class="mr-5 mb-5">
        <label>
          <locked-field v-if="useLockedFields"
                        v-model="localCampaign.lockedFields"
                        field="positiveGeoTargetMethod"
                        :locked="isLocked.positiveGeoTargetMethod"
                        @locked="lockToggle('positiveGeoTargetMethod', $event)"></locked-field>
          {{ positiveGeoTargetMethodLabel }}
        </label>
        <div class="mad-lib-container">
          <mad-lib-select :value="localCampaign.positiveGeoTargetMethod"
                          @input="localCampaign.positiveGeoTargetMethod = $event; isLocked['positiveGeoTargetMethod'] = true; updateCampaign({positiveGeoTargetMethod: $event})"
                          :options="positiveGeoTargetMethodOptions" />
        </div>
      </div>

      <div class="mb-5" v-if="hasNegativeGeoTargetMethodCapability">
        <label>
          <locked-field v-if="useLockedFields"
                        v-model="localCampaign.lockedFields"
                        field="negativeGeoTargetMethod"
                        :locked="isLocked.negativeGeoTargetMethod"
                        @locked="lockToggle('negativeGeoTargetMethod', $event)"></locked-field>
          Negative Geo Target Method:
        </label>
        <div class="mad-lib-container">
          <mad-lib-select :value="localCampaign.negativeGeoTargetMethod"
                          @input="localCampaign.negativeGeoTargetMethod = $event; isLocked['negativeGeoTargetMethod'] = true; updateCampaign({negativeGeoTargetMethod: $event})"
                          :options="negativeGeoTargetMethodOptions" />
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import LockedField from '../../../common/lockedField'
import MadLibSelect from 'core-ui/components/inputs/madLibSelect'

export default {
  name: 'geoTargetMethod',
  components: { MadLibSelect, LockedField },
  props: {
    useLockedFields: {
      type: Boolean
    },
    value: { // campaign || ad group
      type: Object
    },
    cols: {
      type: String,
      default: ''
    },
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  async created () {
    const [positiveEnums, negativeEnums] = await Promise.all([
      this.$res.fetch.positiveGeoTargetMethodEnum({
        advertisingChannelId: this.value.advertisingChannelId
      }),
      this.$res.fetch.negativeGeoTargetMethodEnums()
    ])

    if (positiveEnums) {
      this.positiveGeoTargetMethodEnums = positiveEnums
    }
    if (negativeEnums) {
      this.negativeGeoTargetMethodEnums = negativeEnums
    }
  },
  data () {
    return {
      positiveGeoTargetMethodEnums: [],
      negativeGeoTargetMethodEnums: [],
      isLocked: {
        positiveGeoTargetMethod: false,
        negativeGeoTargetMethod: false
      },
      localCampaign: {}
    }
  },
  computed: {
    positiveGeoTargetMethodLabel () {
      if (this.value?.advertisingChannelId && parseInt(this.value.advertisingChannelId) === 2) {
        return 'Location Types:'
      }
      return 'Positive Geo Target Method:'
    },
    positiveGeoTargetMethodOptions () {
      return this.positiveGeoTargetMethodEnums?.map((x) => {
        if (x.value === 'DONT_CARE' && typeof this.localCampaign.positiveGeoTargetMethod === 'undefined') {
          return { text: x.text, value: undefined }
        } else {
          return x
        }
      }) ?? []
    },
    hasNegativeGeoTargetMethodCapability () {
      if (this.value?.capabilities?.GeoTargetMethodExclude) {
        return true
      }
      return false
    },
    negativeGeoTargetMethodOptions () {
      return this.negativeGeoTargetMethodEnums?.map((x) => {
        if (x.value === 'DONT_CARE' && typeof this.localCampaign.negativeGeoTargetMethod === 'undefined') {
          return { text: x.text, value: undefined }
        } else {
          return x
        }
      }) ?? []
    },
    negativeTargetMethodText () {
      return this.negativeGeoTargetMethodOptions.find(option => option.value === this.localCampaign.negativeGeoTargetMethod)?.text
    },
    positiveTargetMethodText () {
      return this.positiveGeoTargetMethodOptions.find(option => option.value === this.localCampaign.positiveGeoTargetMethod)?.text
    }
  },
  watch: {
    value: {
      handler (newVal) {
        if (newVal) {
          if (newVal.lockedFields) {
            this.$setCompat(this.isLocked, 'positiveGeoTargetMethod', newVal.lockedFields.includes('positiveGeoTargetMethod'))
            this.$setCompat(this.isLocked, 'negativeGeoTargetMethod', newVal.lockedFields.includes('negativeGeoTargetMethod'))
          }

          this.$setCompat(this, 'localCampaign', newVal)
        }
      },
      immediate: true
    },
    isLocked: {
      handler (newVal) {
        // only emit the change if we change the locked fields
        if (this.localCampaign && this.localCampaign.lockedFields !== newVal?.lockedFields) {
          this.updateCampaign(this.localCampaign)
        }
      },
      deep: true
    }
  },
  methods: {
    lockToggle (field, value) {
      this.$setCompat(this.isLocked, field, value)

      // this.$store.commit('activeItem', { ...this.localCampaign, dirty: true })
    },
    updateCampaign (payload) {
      this.$emit('input', {
        ...this.localCampaign,
        ...payload
      })
    }
  },
  async mounted () {

  }
}
</script>

<style scoped>

</style>
